<template>
  <v-container class="pt-7 pb-11">
    <h1 class="text-h4 mb-5">{{ 'myRequests'|localize }}</h1>
    <div class="white elevation-2 rounded">
      <v-row class="my-0 mb-1">
        <v-col class="py-0">
          <div class="d-flex align-center">
            <v-tabs v-model="tab" background-color="transparent" class="grey--text text--lighten-1">
              <v-tab :ripple="false" href="#new" :disabled="loading" class="text-none">
                {{ 'new'|localize }}
              </v-tab>
              <v-tab :ripple="false" href="#pending" :disabled="loading" class="text-none">
                {{ 'pending'|localize }}
              </v-tab>
              <v-tab :ripple="false" href="#booked" :disabled="loading" class="text-none">
                {{ 'booked_title'|localize }}
              </v-tab>
              <v-tab :ripple="false" href="#not_booked" :disabled="loading" class="text-none">
                {{ 'not_booked'|localize }}
              </v-tab>
            </v-tabs>
            <v-spacer/>
            <v-col cols="auto" class="py-0">
              <v-btn text color="primary" @click="resetFilters();clearRequestList();getRequests()"><v-icon left>mdi-sync</v-icon> {{ 'resetFilters'|localize }}</v-btn>
            </v-col>
          </div>
          <v-divider/>
        </v-col>
      </v-row>
      <v-row class="ma-0 mb-1">
        <v-col cols="4">
          <v-text-field
            :background-color="search ? 'serenade' : ''"
            v-model.trim="search"
            prepend-inner-icon="mdi-magnify"
            outlined
            hide-details
            dense
            clearable
            :placeholder="'search'|localize"
          />
        </v-col>
      </v-row>
      <v-container class="py-0">
        <v-row class="my-0 mb-2">
          <v-col cols="3" class="py-0 pr-1">
            <v-autocomplete
              :background-color="filters.country_from.length ? 'serenade' : ''"
              v-model="filters.country_from"
              :items="COUNTRY_LIST"
              :item-text="item => `${item.name} ${item.code}`"
              :item-value="item => `${item.code}`"
              dense
              hide-details
              outlined
              :placeholder="'from'|localize"
              clearable
              multiple
              @change="clearRequestList();getRequests();"
            >
              <template v-slot:item="{ item, on }">
                <v-list-item-title>{{ item.name }} ({{item.code}})</v-list-item-title>
              </template>
              <template v-slot:selection="{ item, index }">
                <span v-if="index === 0" class="text-truncate mr-1" style="max-width: 100px">{{ item.name }}</span>
                <span v-if="index === 1" class="grey--text text-caption mr-1">+{{ filters.country_from.length - 1 }}</span>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="3" class="py-0 px-1">
            <v-autocomplete
              :background-color="filters.country_to.length ? 'serenade' : ''"
              v-model="filters.country_to"
              :items="COUNTRY_LIST"
              :item-text="item => `${item.name} ${item.code}`"
              :item-value="item => `${item.code}`"
              dense
              hide-details
              outlined
              :placeholder="'to'|localize"
              clearable
              multiple
              @change="clearRequestList();getRequests();"
            >
              <template v-slot:item="{ item, on }">
                <v-list-item-title>{{ item.name }} ({{item.code}})</v-list-item-title>
              </template>
              <template v-slot:selection="{ item, index }">
                <span v-if="index === 0" class="text-truncate mr-1" style="max-width: 100px">{{ item.name }}</span>
                <span v-if="index === 1" class="grey--text text-caption mr-1">+{{ filters.country_to.length - 1 }}</span>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col v-if="tab === 'not_booked'" cols="3" class="py-0 px-1">
            <v-select
              :background-color="filters.status.length ? 'serenade' : ''"
              v-model="filters.status"
              :items="statusesList"
              item-text="name"
              item-value="value"
              dense
              hide-details
              outlined
              clearable
              multiple
              :placeholder="'status'|localize"
              @change="clearRequestList();getRequests();"
            >
              <template v-slot:item="{ item }">
                <v-icon :color="STATUS_COLORS[item.value]" size="20" class="mr-2">{{ STATUS_ICONS[item.value] }}</v-icon>
                <v-list-item-title colo>{{ item.value|localize }}</v-list-item-title>
              </template>
              <template v-slot:selection="{ item, index }">
                <v-icon v-if="index <= 2" :color="STATUS_COLORS[item.value]" size="20" class="mr-2">{{ STATUS_ICONS[item.value] }}</v-icon>
                <span v-if="index === 3" class="grey--text text-caption">+{{ filters.status.length - 3 }}</span>
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-row class="my-0 mb-2">
          <v-col cols="3" class="py-0 pr-1">
            <v-select
              :background-color="filters.special_requirements.length ? 'serenade' : ''"
              v-model="filters.special_requirements"
              :items="requirementsList"
              item-text="value"
              item-value="key"
              dense
              hide-details
              outlined
              clearable
              multiple
              :placeholder="'specialRequirements'|localize"
              @change="clearRequestList();getRequests();"
            >
              <template v-slot:item="{ item, on }">
                <v-icon class="mr-2" :color="SPECIAL_REQUIREMENTS_COLORS[item['key']]">{{ SPECIAL_REQUIREMENTS_ICONS[item['key']] }}</v-icon>
                <v-list-item-title>{{ item['key']|localize }}</v-list-item-title>
              </template>
              <template v-slot:selection="{ item, index }">
                <v-icon class="mr-2" :color="SPECIAL_REQUIREMENTS_COLORS[item['key']]">{{ SPECIAL_REQUIREMENTS_ICONS[item['key']] }}</v-icon>
              </template>
            </v-select>
          </v-col>
          <v-col cols="3" class="py-0 px-1">
            <v-menu
              ref="menuDate"
              v-model="menuDate"
              :close-on-content-click="false"
              :return-value.sync="filters.date"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :background-color="filters.date.length ? 'serenade' : ''"
                  :placeholder="'dateCreated' | localize"
                  :value="dateRangeText"
                  readonly
                  dense
                  hide-details
                  outlined
                  clearable
                  v-on="on"
                  @click:clear="filters.date = [];clearRequestList();getRequests();"
                />
              </template>
              <v-date-picker
                v-model="filters.date"
                no-title
                scrollable
                range
                @input="filters.date.length === 2 && $refs.menuDate.save(filters.date)"
                @change="clearRequestList();getRequests();"
              />
            </v-menu>
          </v-col>
          <v-col cols="3" class="py-0 px-1">
            <v-select
              :background-color="filters.request_type && 'serenade'"
              v-model="filters.request_type"
              :items="requestTypesList"
              item-text="name"
              item-value="value"
              dense
              hide-details
              outlined
              clearable
              :placeholder="'requestType'|localize"
              @change="clearRequestList();getRequests();"
            />
          </v-col>
          <v-col cols="3" class="py-0 pl-1">
            <v-select
              :background-color="filters.transport_mode.length ? 'serenade' : ''"
              v-model="filters.transport_mode"
              :items="transportModeList"
              item-text="name"
              item-value="value"
              dense
              hide-details
              outlined
              clearable
              multiple
              :placeholder="'transportMode'|localize"
              @change="clearRequestList();getRequests();"
            >
              <template v-slot:item="{ item, on }">
                <v-icon class="mr-2" color="silver-chalice">{{ TRANSPORT_ICONS[item['value']] }}</v-icon>
                <v-list-item-title>{{ item.value|localize }}</v-list-item-title>
              </template>
              <template v-slot:selection="{ item, index }">
                <v-icon v-if="index <= 2" class="mr-2" color="silver-chalice">{{ TRANSPORT_ICONS[item['value']] }}</v-icon>
                <span v-if="index === 3" class="grey--text text-caption">+{{ filters.transport_mode.length - 3 }}</span>
              </template>
            </v-select>
          </v-col>
        </v-row>
      </v-container>
      <v-data-table
        :headers="headers"
        :items="requestList"
        :loading="loading"
        item-key="id + transport_mode"
        loader-height="2"
        :sort-by="defaultSortBy"
        :sort-desc="defaultSortDesc"
        :options.sync="options"
        :server-items-length="totalItems"
        :no-data-text="'noDataAvailable'|localize"
        hide-default-footer
      >
        <template v-slot:item.title="{ item }">
          <v-tooltip v-for="type in item['special_requirements']" :key="type" top color="black">
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="mr-1" v-bind="attrs" v-on="on" :color="SPECIAL_REQUIREMENTS_COLORS[type]">{{ SPECIAL_REQUIREMENTS_ICONS[type] }}</v-icon>
            </template>
            <span>{{ type|localize }}</span>
          </v-tooltip>
          <router-link class="black--text" :to="{name: 'company-request-details', query: {request_id: item.id, mode: item.transport_mode}}">
            {{ item.title }}
          </router-link>
        </template>
        <template v-slot:item.request_type="{ item }">
          <span>{{ item.request_type|localize }}</span>
        </template>
        <template v-slot:item.volume="{ item }">
          <span v-if="item['volume'] && item['volume'] > 0"> {{item.volume}} </span>
          <span v-else>--</span>
        </template>
        <template v-slot:item.transport_mode="{ item }">
          <v-tooltip top color="black">
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="ml-7" v-bind="attrs" v-on="on" color="silver-chalice">
                {{ TRANSPORT_ICONS[item.transport_mode] }}
              </v-icon>
            </template>
            <span>{{ item.transport_mode|localize }}</span>
          </v-tooltip>
          <v-tooltip v-if="item['proposals'] && item['proposals'].length" right color="black">
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on" class="d-inline-flex align-center">
                <v-chip x-small label class="primary ml-1">{{ item['proposals'].length }}</v-chip>
              </div>
            </template>
            <template>
              <div class="py-1">
                <h5 class="text-subtitle-2 mb-1">{{ 'quotes'|localize }}</h5>
                <ul class="px-2">
                  <li v-for="(quote, i) in item['proposals']" :key="i" class="mb-1">
                    <div v-if="quote['offer_reference']">
                      <i class="text-caption mr-1">{{ 'quoteReference'|localize }}:</i>
                      <i class="text-caption">{{ quote['offer_reference'] }}</i>
                    </div>
                    <div>
                      <i class="text-caption mr-1">{{ 'quotation'|localize }}:</i>
                      <i class="text-caption">{{ quote['proposal'] }}</i>
                    </div>
                    <div>
                      <i class="text-caption mr-1">{{ 'quoteValidTo'|localize }}:</i>
                      <i class="text-caption">{{ quote['valid_to'] }}</i>
                    </div>
                  </li>
                </ul>
              </div>
            </template>
          </v-tooltip>
        </template>
        <template v-slot:item.deadline="{ item }">
          <div v-html="$options.filters.formatDate(item['deadline'])"/>
        </template>
        <template v-slot:item.booked_at="{ item }">
          <div v-html="$options.filters.formatDate(item['booked_at'])"/>
        </template>
        <template v-slot:item.status="{ item }">
          <div class="d-flex align-center">
            <v-icon :color="STATUS_COLORS[item.status]" size="20" class="mr-2">{{ STATUS_ICONS[item.status] }}</v-icon>
            <span>{{ item.status|localize }}</span>
          </div>
        </template>
      </v-data-table>
      <v-pagination
        color="primary"
        v-model="currentPage"
        :length="totalPages"
        :total-visible="7"
        class="py-6"
        :disabled="loading"
        @input="requestList = [];getRequests();"
      />
    </div>
  </v-container>
</template>

<script>
  import SimpleDialog from '@/components/common/SimpleDialog';
  import localize from '@/filters/localize';
  import api from '@/api/api';
  import {TRANSPORT_TYPES, TRANSPORT_ICONS} from '@/enums/transportMode';
  import {STATUSES, STATUS_COLORS, STATUS_ICONS} from '@/enums/statuses';
  import { SPECIAL_REQUIREMENTS_COLORS, SPECIAL_REQUIREMENTS_ICONS } from '@/enums/specialRequirements';
  import {COUNTRY_LIST} from '@/enums/countryList';

  export default {
    name: 'CompanyRequests',
    components: {SimpleDialog},

    data() {
      return {
        TRANSPORT_TYPES,
        TRANSPORT_ICONS,
        STATUSES,
        STATUS_COLORS,
        STATUS_ICONS,
        tab: 'new',
        loading: true,
        debouncedSearch: '',
        debounceTimeout: null,
        defaultSortBy: 'date_created',
        defaultSortDesc: true,
        options: {},
        itemsPerPage: 20,
        currentPage: 1,
        totalPages: 0,
        totalItems: 0,
        requestList: [],
        menuDate: false,
        filters: {
          country_from: [],
          country_to: [],
          status: [],
          transport_mode: [],
          request_type: null,
          date: [],
          special_requirements: []
        }
      };
    },

    watch: {
      tab() {
        this.filters.status = [];
        this.clearRequestList();
        this.getRequests();
      },
      options: {
        handler() {
          this.clearRequestList();
          this.getRequests();
        },
        deep: true,
      },
      search() {
        this.clearRequestList();
        this.getRequests();
      },
    },

    computed: {
      headers() {
        let localHeaders = [
          {text: 'ID', value: 'id', width: 80},
          {text: localize('title'), value: 'title', width: '30%'},
          {text: localize('volume'), value: 'volume', width: 100},
          {text: localize('requestType'), value: 'request_type', width: '25%'},
          {text: localize('transportMode'), value: 'transport_mode', width: 150},
          {text: localize('dateBooked'), value: 'booked_at', width: 125},
          {text: localize('dateCreated'), value: 'date_created', width: 125},
          {text: localize('deadline'), value: 'deadline', width: 155},
          {text: localize('status'), value: 'status', width: 150},
        ];
        if (this.tab === 'new' || this.tab === 'pending') {
          localHeaders = localHeaders.filter((item) => item.value !== 'status' && item.value !== 'booked_at');
        }
        if (this.tab === 'booked') {
          localHeaders = localHeaders.filter((item) => item.value !== 'deadline' && item.value !== 'status' && item.value !== 'date_created');
        }
        if (this.tab === 'not_booked') {
          localHeaders = localHeaders.filter((item) => item.value !== 'booked_at');
        }
        return localHeaders;
      },
      COUNTRY_LIST() {
        return COUNTRY_LIST
      },
      SPECIAL_REQUIREMENTS_ICONS() {
        return SPECIAL_REQUIREMENTS_ICONS
      },
      SPECIAL_REQUIREMENTS_COLORS() {
        return SPECIAL_REQUIREMENTS_COLORS
      },
      userData() {
        return this.$store.getters.userData;
      },
      requestTypesList() {
        return this.$store.getters.requestTypesList;
      },
      transportModeList() {
        return this.$store.getters.transportModeList;
      },
      requirementsList() {
        return this.$store.getters.requirementsList;
      },
      statusesList() {
        let statuses = this.$store.getters.statuses;
        const requiredStatuses = [
          STATUSES.CLOSED,
          STATUSES.DECLINED,
          STATUSES.REJECTED,
          STATUSES.NOT_BOOKED,
          STATUSES.CANCELLED
        ];
        return statuses.filter(status => requiredStatuses.includes(status.value));
      },
      dateRangeText () {
        return this.filters.date.sort().join(' ~ ')
      },
      search: {
        get() {
          return this.debouncedSearch
        },
        set(val) {
          if (this.debounceTimeout) clearTimeout(this.debounceTimeout)
          this.debounceTimeout = setTimeout(() => {
            this.debouncedSearch = val
          }, 500)
        }
      },
    },

    methods: {
      resetFilters() {
        this.filters.country_from = [];
        this.filters.country_to = [];
        this.filters.status = [];
        this.filters.transport_mode = [];
        this.filters.request_type = null;
        this.filters.date = [];
        this.filters.special_requirements = [];
      },
      clearRequestList() {
        this.requestList = [];
        this.totalPages = 0;
        this.totalItems = 0;
        this.currentPage = 1;
      },
      queryData() {
        let queryData = {};
        queryData['tab'] = this.tab;
        queryData['per_page'] = this.itemsPerPage;
        queryData['page'] = this.currentPage;
        this.options.sortBy[0] && (
          queryData['sort'] = {
            field: this.options.sortBy[0],
            desc: this.options.sortDesc[0],
          });
        this.search && (queryData['search'] = this.search);
        queryData['company_id'] = this.userData.company.id;
        queryData.filters = {};
        this.filters.country_from.length && (queryData.filters.country_from = this.filters.country_from);
        this.filters.country_to.length && (queryData.filters.country_to = this.filters.country_to);
        this.filters.status.length && (queryData.filters.status = this.filters.status);
        this.filters.transport_mode.length && (queryData.filters.transport_mode = this.filters.transport_mode);
        this.filters.request_type && (queryData.filters.request_type = [this.filters.request_type]);
        this.filters.date.length && (queryData.filters.date = this.filters.date.sort());
        this.filters.special_requirements.length && (queryData.filters.special_requirements = this.filters.special_requirements);
        return queryData;
      },
      setRequestsData(requestsData) {
        this.requestList = requestsData.data.data;
        this.totalPages = requestsData.data.pagination['total_page'];
        this.totalItems = requestsData.data.pagination['total_items'];
      },
      async getRequests() {
        try {
          this.loading = true;
          let requestsData = await api.getCompanyRequests(this.queryData());
          this.setRequestsData(requestsData);
          this.loading = false;
        } catch (e) {
          this.loading = false;
        }
      },
    },
  };
</script>
