<template>
  <v-container class="pt-7 pb-11">
    <h1 class="text-h4 mb-5">{{ 'myQuotes'|localize }}</h1>
    <div class="white elevation-2 rounded">
      <v-row class="my-0 mb-1">
        <v-col class="py-0">
          <div class="d-flex align-center">
            <v-tabs v-model="tab" @change="$router.replace({query: $router.currentRoute.query, hash: tab})" background-color="transparent" class="grey--text text--lighten-1">
              <v-tab :ripple="false" href="#all" :disabled="loading" class="text-none">
                {{ 'allRequests'|localize }}
              </v-tab>
              <v-tab :ripple="false" href="#need_response" :disabled="loading" class="text-none">
                {{ 'needResponse'|localize }}
              </v-tab>
              <v-tab :ripple="false" href="#accepted_booked" :disabled="loading" class="text-none">
                {{ 'booked'|localize }}
              </v-tab>
              <v-tab :ripple="false" href="#declined" :disabled="loading" class="text-none">
                {{ 'declined'|localize }}
              </v-tab>
              <v-tab :ripple="false" href="#closed" :disabled="loading" class="text-none">
                {{ 'closed'|localize }}
              </v-tab>
            </v-tabs>
            <v-spacer/>
            <v-col cols="auto" class="py-0">
              <v-btn text color="primary" @click="resetFilters();clearRequestList();getRequests()"><v-icon left>mdi-sync</v-icon> {{ 'resetFilters'|localize }}</v-btn>
            </v-col>
            <v-btn :to="{name: 'new-request'}" color="primary" depressed rounded class="mr-2">
              <v-icon size="25" left class="mr-3">mdi-plus</v-icon>
              {{ 'newRequest'|localize }}
            </v-btn>
          </div>
          <v-divider/>
        </v-col>
      </v-row>
      <v-row class="ma-0 mb-1">
        <v-col cols="4">
          <v-text-field
            :background-color="search ? 'serenade' : ''"
            v-model.trim="search"
            prepend-inner-icon="mdi-magnify"
            outlined
            hide-details
            dense
            clearable
            :placeholder="'search'|localize"
          />
        </v-col>
      </v-row>
      <v-container class="py-0">
        <v-row class="my-0 mb-2">
          <v-col cols="3" class="py-0 pr-1">
            <v-autocomplete
              :background-color="filters.country_from.length ? 'serenade' : ''"
              v-model="filters.country_from"
              :items="COUNTRY_LIST"
              :item-text="item => `${item.name} ${item.code}`"
              :item-value="item => `${item.code}`"
              dense
              hide-details
              outlined
              :placeholder="'from'|localize"
              clearable
              multiple
              @change="clearRequestList();getRequests();"
            >
              <template v-slot:item="{ item, on }">
                <v-list-item-title>{{ item.name }} ({{item.code}})</v-list-item-title>
              </template>
              <template v-slot:selection="{ item, index }">
                <span v-if="index === 0" class="text-truncate mr-1" style="max-width: 100px">{{ item.name }}</span>
                <span v-if="index === 1" class="grey--text text-caption mr-1">+{{ filters.country_from.length - 1 }}</span>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="3" class="py-0 px-1">
            <v-autocomplete
              :background-color="filters.country_to.length ? 'serenade' : ''"
              v-model="filters.country_to"
              :items="COUNTRY_LIST"
              :item-text="item => `${item.name} ${item.code}`"
              :item-value="item => `${item.code}`"
              dense
              hide-details
              outlined
              :placeholder="'to'|localize"
              clearable
              multiple
              @change="clearRequestList();getRequests();"
            >
              <template v-slot:item="{ item, on }">
                <v-list-item-title>{{ item.name }} ({{item.code}})</v-list-item-title>
              </template>
              <template v-slot:selection="{ item, index }">
                <span v-if="index === 0" class="text-truncate mr-1" style="max-width: 100px">{{ item.name }}</span>
                <span v-if="index === 1" class="grey--text text-caption mr-1">+{{ filters.country_to.length - 1 }}</span>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col v-if="tab === 'all'" cols="3" class="py-0 px-1">
            <v-select
              :background-color="filters.status.length ? 'serenade' : ''"
              v-model="filters.status"
              :items="statusesList"
              item-text="name"
              item-value="value"
              dense
              hide-details
              outlined
              clearable
              multiple
              :placeholder="'status'|localize"
              @change="clearRequestList();getRequests()"
            >
              <template v-slot:item="{ item }">
                <v-icon :color="STATUS_COLORS[item.value]" size="20" class="mr-2">{{ STATUS_ICONS[item.value] }}</v-icon>
                <v-list-item-title colo>{{ item.value|localize }}</v-list-item-title>
              </template>
              <template v-slot:selection="{ item, index }">
                <v-icon v-if="index <= 2" :color="STATUS_COLORS[item.value]" size="20" class="mr-2">{{ STATUS_ICONS[item.value] }}</v-icon>
                <span v-if="index === 3" class="grey--text text-caption">+{{ filters.status.length - 3 }}</span>
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-row class="my-0 mb-2">
          <v-col cols="3" class="py-0 pr-1">
            <v-select
              :background-color="filters.special_requirements.length ? 'serenade' : ''"
              v-model="filters.special_requirements"
              :items="requirementsList"
              item-text="value"
              item-value="key"
              dense
              hide-details
              outlined
              clearable
              multiple
              :placeholder="'specialRequirements'|localize"
              @change="clearRequestList();getRequests();"
            >
              <template v-slot:item="{ item, on }">
                <v-icon class="mr-2" :color="SPECIAL_REQUIREMENTS_COLORS[item['key']]">{{ SPECIAL_REQUIREMENTS_ICONS[item['key']] }}</v-icon>
                <v-list-item-title>{{ item['key']|localize }}</v-list-item-title>
              </template>
              <template v-slot:selection="{ item, index }">
                <v-icon class="mr-2" :color="SPECIAL_REQUIREMENTS_COLORS[item['key']]">{{ SPECIAL_REQUIREMENTS_ICONS[item['key']] }}</v-icon>
              </template>
            </v-select>
          </v-col>
          <v-col cols="3" class="py-0 px-1">
            <v-menu
              ref="menuDate"
              v-model="menuDate"
              :close-on-content-click="false"
              :return-value.sync="filters.date"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :background-color="filters.date.length ? 'serenade' : ''"
                  :placeholder="'dateCreated' | localize"
                  :value="dateRangeText"
                  readonly
                  dense
                  hide-details
                  outlined
                  clearable
                  v-on="on"
                  @click:clear="filters.date = [];clearRequestList();getRequests()"
                />
              </template>
              <v-date-picker
                v-model="filters.date"
                no-title
                scrollable
                range
                @input="filters.date.length === 2 && $refs.menuDate.save(filters.date)"
                @change="clearRequestList();getRequests()"
              />
            </v-menu>
          </v-col>
          <v-col cols="3" class="py-0 px-1">
            <v-select
              :background-color="filters.request_type && 'serenade'"
              v-model="filters.request_type"
              :items="requestTypesList"
              item-text="name"
              item-value="value"
              dense
              hide-details
              outlined
              clearable
              :placeholder="'requestType'|localize"
              @change="clearRequestList();getRequests()"
            />
          </v-col>
          <v-col cols="3" class="py-0 pl-1">
            <v-select
              :background-color="filters.transport_mode.length ? 'serenade' : ''"
              v-model="filters.transport_mode"
              :items="transportModeList"
              item-text="name"
              item-value="value"
              dense
              hide-details
              outlined
              clearable
              multiple
              :placeholder="'transportMode'|localize"
              @change="clearRequestList();getRequests()"
            >
              <template v-slot:item="{ item, on }">
                <v-icon class="mr-2" color="silver-chalice">{{ TRANSPORT_ICONS[item['value']] }}</v-icon>
                <v-list-item-title>{{ item.value|localize }}</v-list-item-title>
              </template>
              <template v-slot:selection="{ item, index }">
                <v-icon v-if="index <= 2" class="mr-2" color="silver-chalice">{{ TRANSPORT_ICONS[item['value']] }}</v-icon>
                <span v-if="index === 3" class="grey--text text-caption">+{{ filters.transport_mode.length - 3 }}</span>
              </template>
            </v-select>
          </v-col>
        </v-row>
      </v-container>
      <v-row v-if="tab === 'need_response' || tab === 'accepted_booked'" class="ma-0">
        <v-col cols="12">
          <v-alert class="my-0" dense outlined text color="dark grey">{{ 'customerNote'|localize }}</v-alert>
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="requestList"
        :loading="loading"
        loader-height="2"
        :options.sync="options"
        :server-items-length="totalItems"
        :no-data-text="'noDataAvailable'|localize"
        hide-default-footer
      >
        <template v-slot:item.title="{ item }">
          <div class="py-2">
            <div>
              <v-tooltip v-for="type in item['special_requirements']" :key="type" top color="black">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon class="mr-1" v-bind="attrs" v-on="on" :color="SPECIAL_REQUIREMENTS_COLORS[type]">{{ SPECIAL_REQUIREMENTS_ICONS[type] }}</v-icon>
                </template>
                <span>{{ type|localize }}</span>
              </v-tooltip>
              <router-link class="black--text" style="vertical-align: middle" :to="{name: 'request-details', query: {request_id: item.id}}">
                {{ item.title }}
              </router-link>
            </div>
            <div v-if="item['customer_ref']">
              <i class="name-col text-caption">{{ 'customerRef'|localize }}: {{ item['customer_ref'] }}</i>
            </div>
          </div>
        </template>
        <template v-slot:item.request_type="{ item }">
          <span>{{ item.request_type|localize }}</span>
        </template>
        <template v-slot:item.transport_modes="{ item }">
          <v-tooltip v-for="mode in item['transport_modes']" :key="mode" top color="black">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" :color="item['booked_transport_mode'] === mode ? 'primary' : 'silver-chalice'">{{ TRANSPORT_ICONS[mode] }}</v-icon>
            </template>
            <span>{{ mode|localize }}</span>
          </v-tooltip>
          <div v-if="item['proposals_count']" class="d-flex align-center">
            <i class="name-col text-caption mr-1">{{ 'quotes'|localize }}: </i>
            <v-chip x-small label>{{ item['proposals_count'] }}</v-chip>
          </div>
        </template>
        <template v-slot:item.deadline="{ item }">
          <div v-html="$options.filters.formatDate(item['deadline'])"/>
        </template>
        <template v-slot:item.settings="{ item }">
          <v-progress-circular
            v-if="deleteQueue.includes(item.id)"
            indeterminate
            :width="1"
            :size="20"
          />
          <v-menu v-else left transition="slide-y-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-avatar v-bind="attrs" v-on="on" size="45" tile>
                <v-icon size="20">mdi-dots-vertical</v-icon>
              </v-avatar>
            </template>
            <v-list>
              <v-list-item link @click="editRequest(item)">
                <v-list-item-title v-if="isDraft(item)">{{ 'edit'|localize }}</v-list-item-title>
                <v-list-item-title v-else>{{ 'view'|localize }}</v-list-item-title>
              </v-list-item>
              <v-list-item link @click="copyRequest(item)">
                <v-list-item-title>{{ 'copy'|localize }}</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="ifDeletable(item)" link @click="openDelete(item)">
                <v-list-item-title>{{ 'delete'|localize }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:item.status="{ item }">
          <div class="d-flex align-center">
            <v-icon :color="STATUS_COLORS[item.status]" size="20" class="mr-2">{{ STATUS_ICONS[item.status] }}</v-icon>
            <span>{{ item.status|localize }}</span>
          </div>
        </template>
      </v-data-table>
      <v-pagination
        color="primary"
        v-model="currentPage"
        :length="totalPages"
        :total-visible="7"
        class="py-6"
        :disabled="loading"
        @input="requestList = [];getRequests();"
      />
      <SimpleDialog
        ref="deleteDialog"
        :title="'deleteRequestTitle'|localize"
        :text="'deleteRequestText'|localize"
        :accept-text="'delete'|localize"
        :decline-text="'back'|localize"
        @accept="deleteRequest"
        @decline="cancelDelete"
      />
    </div>
  </v-container>
</template>

<script>
  import SimpleDialog from '@/components/common/SimpleDialog';
  import localize from '@/filters/localize';
  import api from '@/api/api';
  import {TRANSPORT_TYPES, TRANSPORT_ICONS} from '@/enums/transportMode';
  import {STATUSES, STATUS_ICONS, STATUS_COLORS} from '@/enums/statuses';
  import { SPECIAL_REQUIREMENTS_COLORS, SPECIAL_REQUIREMENTS_ICONS } from '@/enums/specialRequirements';
  import {COUNTRY_LIST} from '@/enums/countryList';

  export default {
    name: 'CustomerRequests',
    components: {SimpleDialog},

    data() {
      return {
        TRANSPORT_TYPES,
        TRANSPORT_ICONS,
        STATUSES,
        STATUS_ICONS,
        STATUS_COLORS,
        tab: this.initialHash(),
        loading: true,
        debouncedSearch: this.initialSearch(),
        debounceTimeout: null,
        options: {},
        itemsPerPage: 20,
        currentPage: 1,
        totalPages: 0,
        totalItems: 0,
        requestList: [],
        deleteQueue: [],
        deleteRequestId: null,
        menuDate: false,
        filters: this.initialFilters()
      };
    },

    watch: {
      tab() {
        this.filters.status = [];
        this.clearRequestList();
        this.getRequests();
      },
      options: {
        handler() {
          this.clearRequestList();
          this.getRequests();
        },
        deep: true,
      },
      search() {
        this.clearRequestList();
        this.getRequests();
        this.handleSearchQuery();
      },
      filters: {
        handler() {
          this.handleFiltersQuery()
        },
        deep: true,
      },
    },

    computed: {
      COUNTRY_LIST() {
        return COUNTRY_LIST
      },
      SPECIAL_REQUIREMENTS_ICONS() {
        return SPECIAL_REQUIREMENTS_ICONS
      },
      SPECIAL_REQUIREMENTS_COLORS() {
        return SPECIAL_REQUIREMENTS_COLORS
      },
      requirementsList() {
        return this.$store.getters.requirementsList;
      },
      requestTypesList() {
        return this.$store.getters.requestTypesList;
      },
      transportModeList() {
        return this.$store.getters.transportModeList;
      },
      headers() {
        let localHeaders = [
          {text: 'ID', value: 'id', width: 80},
          {text: localize('dateCreated'), value: 'date_created', width: 135},
          {text: localize('title'), value: 'title', width: '25%'},
          {text: localize('requestType'), value: 'request_type', width: '20%'},
          {text: localize('transportMode'), value: 'transport_modes', sortable: false, width: 135},
          {text: localize('deadlineForResponse'), value: 'deadline', width: 155},
          {text: localize('status'), value: 'status', width: 90},
          {text: '', value: 'settings', sortable: false, width: 50, align: 'center'},
        ];
        if (this.tab === 'need_response' || this.tab === 'declined' || this.tab === 'accepted_booked') {
          localHeaders = localHeaders.filter((item) => item.value !== 'status');
        }
        return localHeaders;
      },
      statusesList() {
        let statuses = this.$store.getters.statuses;
        const acceptedStatuses = [
          STATUSES.NEW,
          STATUSES.NEED_RESPONSE,
          STATUSES.CLOSED,
          STATUSES.DRAFT,
          STATUSES.BOOKED,
          STATUSES.NOT_BOOKED,
          STATUSES.REJECTED,
          STATUSES.CANCELLED
        ];
        return statuses.filter(status => acceptedStatuses.includes(status.value));
      },
      dateRangeText () {
        return this.filters.date.sort().join(' ~ ')
      },
      search: {
        get() {
          return this.debouncedSearch
        },
        set(val) {
          if (this.debounceTimeout) clearTimeout(this.debounceTimeout)
          this.debounceTimeout = setTimeout(() => {
            this.debouncedSearch = val
          }, 500)
        }
      },
    },

    methods: {
      clearRequestList() {
        this.requestList = [];
        this.totalPages = 0;
        this.totalItems = 0;
        this.currentPage = 1;
      },
      queryData() {
        let queryData = {};
        this.tab !== 'all' && (queryData['tab'] = this.tab);
        queryData['per_page'] = this.itemsPerPage;
        queryData['page'] = this.currentPage;
        this.options.sortBy[0] && (
          queryData['sort'] = {
            field: this.options.sortBy[0],
            desc: this.options.sortDesc[0],
          });
        this.search && (queryData['search'] = this.search);
        queryData.filters = {};
        this.filters.country_from.length && (queryData.filters.country_from = this.filters.country_from);
        this.filters.country_to.length && (queryData.filters.country_to = this.filters.country_to);
        this.filters.status.length && (queryData.filters.status = this.filters.status);
        this.filters.transport_mode.length && (queryData.filters.transport_mode = this.filters.transport_mode);
        this.filters.request_type && (queryData.filters.request_type = [this.filters.request_type]);
        this.filters.date.length && (queryData.filters.date = this.filters.date.sort());
        this.filters.special_requirements.length && (queryData.filters.special_requirements = this.filters.special_requirements);
        return queryData;
      },
      setRequestsData(requestsData) {
        this.requestList = requestsData.data.data;
        this.totalPages = requestsData.data.pagination['total_page'];
        this.totalItems = requestsData.data.pagination['total_items'];
      },
      async getRequests() {
        try {
          this.loading = true;
          let requestsData = await api.getCustomerRequests(this.queryData());
          this.setRequestsData(requestsData);
          this.loading = false;
        } catch (e) {
          this.loading = false;
        }
      },
      openDelete(item) {
        this.$refs.deleteDialog.open();
        this.deleteRequestId = item.id;
      },
      cancelDelete() {
        this.deleteRequestId = null;
      },
      ifDeletable(item) {
        return item.status === STATUSES.NEW || item.status === STATUSES.DRAFT;
      },
      isDraft(item) {
        return item.status === STATUSES.DRAFT;
      },
      editRequest(request) {
        this.$router.push({name: 'request-details', query: {request_id: request.id}, hash: '#requirements'});
      },
      copyRequest(item) {
        this.$router.push({name: 'new-request', query: {request_id: item.id}});
      },
      async deleteRequest() {
        let requestId = this.deleteRequestId;
        this.deleteRequestId = null;
        this.deleteQueue.push(requestId);
        try {
          await api.deleteCustomerRequest(requestId);
          let requestsData = await api.getCustomerRequests(this.queryData());
          this.setRequestsData(requestsData);
          this.deleteQueue = this.deleteQueue.filter(queueId => queueId !== requestId)
        } catch (e) {}
      },
      resetFilters() {
        this.filters.country_from = [];
        this.filters.country_to = [];
        this.filters.status = [];
        this.filters.transport_mode = [];
        this.filters.request_type = null;
        this.filters.date = [];
        this.filters.special_requirements = [];
        this.clearFiltersQuery();
      },
      initialHash() {
        const queryHash = this.$router.currentRoute.hash.slice(1);
        return queryHash ? queryHash : 'all';
      },
      initialSearch() {
        const querySearch = this.$router.currentRoute.query?.search;
        return querySearch ? querySearch : '';
      },
      initialFilters() {
        const queryFilters = this.$router.currentRoute.query?.filters;
        if (queryFilters && typeof queryFilters === 'string') {
          return JSON.parse(queryFilters);
        } else {
          return {
            country_from: [],
            country_to: [],
            status: [],
            transport_mode: [],
            request_type: null,
            date: [],
            special_requirements: []
          };
        }
      },
      handleSearchQuery() {
        const querySearchString = this.initialSearch();
        const currentSearchString = this.search || '';
        if (querySearchString !== currentSearchString) {
          let updatedRoute = {...this.$route, query: {...this.$route.query, search: currentSearchString}};
          this.$router.replace(updatedRoute);
        }
      },
      handleFiltersQuery() {
        const queryFiltersString = JSON.stringify(this.initialFilters());
        const currentFiltersString = JSON.stringify(this.filters);
        if (queryFiltersString !== currentFiltersString) {
          let updatedRoute = {...this.$route, query: {...this.$route.query, filters: currentFiltersString}};
          this.$router.replace(updatedRoute);
        }
      },
      clearFiltersQuery() {
        const queryFiltersString = JSON.stringify(this.initialFilters());
        const currentFiltersString = JSON.stringify(this.filters);
        if (queryFiltersString !== currentFiltersString) {
          const updatedRoute = {...this.$route, query: {...this.$route.query, filters: ''}};
          this.$router.replace(updatedRoute);
        }
      }
    },
  };
</script>
